import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import avatar1 from '../imgs/avatar12.PNG'

function Whatsapp() {
  return (
    <div>
      <FloatingWhatsApp accountName='Dr Walaa' phoneNumber='201201517099' avatar={avatar1} />  
    </div>
  )
}

export default Whatsapp