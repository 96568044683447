import './App.css';
import Home from './pages/Home';
import Review from './pages/Review';
import "arabic-fonts/src/css/arabic-fonts.css";
import {  RouterProvider, createBrowserRouter} from "react-router-dom";

let routers = createBrowserRouter([
  {path: '/' , element: <Home className= 'font-muhammadi' /> , indexRoute: true}
  ,{path: '/review' , element: <Review />}
])

function App() {
  return <RouterProvider router={routers} ></RouterProvider>
}

export default App;
