import React, { useEffect, useState } from 'react';
import axios from 'axios';



const AppointmentModal = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState(''); 
  const [reason, setReason] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [clinicLocation , setClinicLocation] = useState('')

  const[isAvailableTime , setIsAvailableTime] = useState(true);

  const formatTime = (inputTime) => {
    const [hours, minutes] = inputTime.split(':');
    return `${hours}:${minutes}:00`;
  };
  

  useEffect(() => {
    setIsAvailableTime(true);
  }, [time]);
 

  const bookingApiUrl = 'https://back-api-231-be356ccc227f.herokuapp.com/api/appointment/';
  const requestBody = {
    name : name,
    phone : phone,
    reason : reason,
    date : date,
    time : formatTime(time),
    clinic_location : clinicLocation
  };
  const handleBookingCall = async () => {
    try {
      const response = await axios.post(bookingApiUrl, requestBody);
      
      console.log(response.data);
      onClose();
    } catch (err) {
      console.log(err);
    }
  }
  
  
  return (
          <div className={`absolute top-0 left-0 w-full flex backdrop-blur flex-col items-center justify-center z-50 lg:z-50 md:z-50 sm:z-50 ${isOpen ? '' : 'hidden'}`}>
              
              <div className="fixed top-0 left-0 w-full h-full bg-gray-800 opacity-50"></div>
              <div className="bg-white rounded-lg shadow-lg p-8 w-96  flex justify-center flex-col">
              {
                isAvailableTime ?  null : <h3 className='bg-pink-500 w-1/2 m-auto rounded-l-md rounded-r-lg p-2 shadow-lg shadow-gray-700 transition-all ease-in-out animate-bounce'>هذا الموعد غير متاح</h3>
              }
                <span className="relative top-2 -right-60 text-gray-600 cursor-pointer bg-pink-500 w-6 p-0.5 rounded-l rounded-r" onClick={onClose}>&times;</span>
                <h2 className="text-2xl font-bold mb-4">احجز موعدك</h2>
                <label className="block mb-4">
                  الاسم
                  <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-400" />
                </label>

                <label className="block mb-4">
                  رقم التليفون

                <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-400" />

                </label>

                <label className="block mb-4">
                  بتشتكي من ايه
                  <input type="text" value={reason} onChange={(e) => setReason(e.target.value)} className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-400" />
                </label>
                <label className="block mb-4">
                  تاريخ الحجز
                  <input type="date" value={date} onChange={(e) => setDate(e.target.value)} className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-400" />
                </label>
                <label className="block mb-4">
                  الساعه
                  <input type="time" value={time} onChange={(e) => setTime(e.target.value) } className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-400" />
                </label>

                <label className="block mb-4">
                  مكان العياده
                  <select
                      onChange={(e) => setClinicLocation(e.target.value)}
                      className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-400"
                  >  
                  <option value="Banha">بنها</option>
                    <option value="Tagamo3">التجمع</option>
                    <option value="Mounfia">المنوفية </option>
                  </select>
                </label>

                <button onClick={handleBookingCall} className="bg-pink-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none">احجز</button>
              </div>
      </div>

  );  


};

export default AppointmentModal;