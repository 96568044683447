import React from 'react'
import NavBar from '../components/NavBar'
import Hero from '../components/Hero'
import About from '../components/About'
import Testimonials from '../components/Testimonials'
import Services from '../components/Services' 
import Gallery from '../components/Gallery'  
import Map from '../components/Map'
import Footer from '../components/Footer'
import Whatsapp from '../components/Whatsapp'
import SocialMedia from '../components/SocialMedia'
import Branches from '../components/Branches'
function Home() {
  return (
    <div>
        <Whatsapp/>
        <SocialMedia />
        <NavBar />
        <Hero />
        <About />
        <Services />
        <Testimonials />
        <Gallery />
        <Map />
        <Branches />
        <Footer />
    </div>
  )
}

export default Home