import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import avatar from "../imgs/avatar.png";

const Testimonials = () => {
  const [reviews , SetReviews] = useState([])

  const getReviews = async () => {
    const url = 'https://back-api-231-be356ccc227f.herokuapp.com/api/review/'
    const response = await axios.get(url)
    SetReviews(response.data)
    console.log(response.data)
  }

  useEffect(
      ()=>{
        
        getReviews()

      },[]

  )

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Add this line for automatic sliding
    autoplaySpeed: 2000, // Set the autoplay speed in milliseconds (adjust as needed)
  };

  return (
    <section className="py-14">
      <div className="max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h3 className="text-pink-500 font-semibold pb-6 text-xl">
            الناس بيقولوا ايه عنا ؟
          </h3>
          <Slider {...settings}>
            {reviews.map((item, idx) => (
              <div key={idx}>
                <figure>
                  <blockquote>
                    <p className="text-gray-800 text-xl font-semibold sm:text-2xl">
                      “{item.review}“
                    </p>
                  </blockquote>
                  <div className="mt-6">
                    <img
                      src={avatar}
                      className="w-16 h-16 mx-auto rounded-full"
                      alt={`Avatar of ${item.name}`}
                    />
                    <div className="mt-3">
                      <span className="block text-gray-800 font-semibold">
                        {item.name}
                      </span>
                      <span className="block text-gray-600 text-sm mt-0.5">
                      </span>
                    </div>
                  </div>
                </figure>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
