import React from 'react'
import ReviewComponent from '../components/ReviewComponent'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'


function Review() {
  return (
    <div>
        <NavBar/>
        <ReviewComponent/>
        <Footer/>

    </div>
  )
}

export default Review