import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import marker from '../imgs/marker.png';
import L from 'leaflet'; // Import Leaflet

function Map() {

    const lat = 30.0192778;
    const lng = 31.5041948;

    const customIcon = new L.Icon({
        iconUrl: {marker},
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
      });
      
 
   return (
        <div className='map w-2/3 m-auto '>
                <MapContainer center={[lat, lng]} zoom={13} style={{ height: '400px', width: '100%' }} className='rounded-l-xl rounded-r-xl shadow-xl shadow-slate-800'>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={[lat, lng]} Icon={marker}>
                    <div style={{ position: 'absolute', top: '-16px', left: '-16px' }}>
                        <img src={marker} alt="Marker Icon" style={{ width: '32px', height: '32px' }} />
                    </div>
                    <Popup>Dr Walaa Salah Dental Clinic</Popup>
                    </Marker>
                </MapContainer>
        </div>

    
  )
}

export default Map
