

const Branches = () => {
    return (
        <div className="ml-4 mt-8">
            <p className="leading-relaxed mt-2 text-[15px]">
                عيادة اسنان الدكتوره ولاء صلاح
                <br></br>
                استشارى تجميل وتقويم الاسنان
                زميل الكليه الملكيه للجراحين في ادنبره
                زمالة بريطانية فى تقويم الأسنان

                طب الفم والأسنان بالقصر العينى
                <br />
                <br />
                {/* فروعنا في بنها و المنوفيه و التجمع الخامس */}
            </p>
            <dt className="text-lg leading-6 font-medium text-gray-900">
                فروعنا
            </dt>


            <div className="w-[50%]">

                <div className="flex justify-center mt-2  text-base text-gray-500">
                    <a href="https://maps.app.goo.gl/G8HKKxFF5Stdp9zb9?g_st=ic" className="mr-4">اضغط هنا للذهاب الي الموقع</a>
                    <span className="mr-4">:</span>
                    <p className="font-bold text-xl">فرع التجمع الخامس</p>
                </div>

                <div className="flex justify-center mt-2  text-base text-gray-500">
                    <a href="https://maps.app.goo.gl/ZoMhHVq7e58fTLPYA?g_st=iw" className="mr-4">اضغط هنا للذهاب الي الموقع</a>
                    <span className="mr-4">:</span>
                    <p className="font-bold text-xl">فرع بنها</p>
                </div>


            </div>

        </div>

    )
}
export default Branches