import React from 'react'
import Shape from './Shape'
import about3 from '../imgs/about3.jpeg'
import about4 from '../imgs/about4.jpeg'



function About() {
  return (
    <div>
        <section className="bg-white dark:bg-gray-900 text-center">
            <h2 className="mb-4 text-6xl tracking-tight font-medium text-gray-900 dark:text-white mt-16">من انا  ؟</h2>
             <div className="grid grid-cols-2 gap-4 mt-8 w-2/3 m-auto p-8">
                    <img className="w-full rounded-lg" src={about3} alt="office content 1">
                    </img>
                    <img className="mt-4 w-full lg:mt-10 rounded-lg" src={about4} alt="office content 2"
                    ></img>
            </div>
         
            <div className="py-0 px-0 max-w-fit lg:grid-cols-2 lg:py-16 lg:px-6 flex flex-col w-screen text-center m-auto items-center justify-center">
                <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400 shadow-xl p-20 rounded-l-3xl rounded-r-3xl sm:w-11/12 lg:w-2/3 sm:rounded-l-3xl sm:rounded-r-3xl sm:shadow-2xl text-center">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-pink-500 dark:text-white">من هي د. ولاء صلاح</h2>
                    <p className="mb-4">
                                            استشارى تجميل وتقويم الاسنان  
                        زميل الكليه الملكيه للجراحين في ادنبره
                        زمالة بريطانية فى تقويم الأسنان
                        طب الفم والأسنان بالقصر العينى
                    </p>
                </div>
                <Shape className='aspect-[2.08] object-contain object-center w-full fill-pink-500 overflow-hidden max-w-[287px] rotate-90 absolute transition-transform animate-pulse ease-in-out opacity-5 translate-y-11 -right-20 top-auto'/>

            </div>
        </section>
        <div>

        </div>
    </div>
  )
}

export default About