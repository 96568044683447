export default () => {

    const features = [
        {
 
            title: "تقويم الأسنان",
            desc: "يتضمن تصحيح ترتيب الأسنان غير الصحيح باستخدام تقنيات مثل الأقواس والأقواس الشفافة، لتحسين المظهر الجمالي والوظيفة الصحية للفم."
        },
        {
            title: "تنظيف الأسنان",
            desc: "يشمل إزالة اللويحة والترسبات عن الأسنان باستخدام أدوات خاصة، مما يحسن صحة اللثة ويحافظ على نضارة الأسنان"
        },
        {
            
            title: "حشوات الأسنان",
            desc: "يتم استخدام مواد حشوية لمعالجة التسوس أو الضرر في الأسنان، مما يعيد بناء الأسنان المتضررة ويحافظ على وظائفها."
        },
        {
          
            title: "علاج جذور الأسنان",
            desc: "يتم إجراء عملية لعلاج الالتهابات في الجذر، مما يساعد في الحفاظ على الأسنان وتجنب استخدام الاستئصال"
        },

        {

            title: "تبييض الأسنان",
            desc: " يستخدم مواد تبييض لتفتيح لون الأسنان وإزالة البقع، مما يعزز الثقة بالنفس ويحسن جاذبية الابتسامة"
        },
        {
 
            title: "تصحيح الثغرات والتشوهات",
            desc: "يشمل إجراءات لتصحيح أي ثغرات أو تشوهات في الأسنان، مثل تركيب الأقواس أو الجسور"
        },
    ]

    return (
        <section className="py-14">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                <div className="relative max-w-2xl mx-auto sm:text-center">
                    <div className="relative z-10">
                        <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                            خدامتنا
                        </h3>
                        <p className="mt-3 text-pink-500">
                            الخدمات الاكثر طلبا في عيادتنا
                        </p>
                    </div>
                    <div className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>
                </div>
                <div className="relative mt-12">
                    <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {
                            features.map((item, idx) => (
                                <li key={idx} className="bg-white space-y-3 p-4 border rounded-lg">
                                    <div className="text-indigo-600 pb-3">
                                        {item.icon}
                                    </div>
                                    <h4 className="text-2xl text-pink-500 font-extrabold">
                                        {item.title}
                                    </h4>
                                    <p className="text-zinc-600">
                                        {item.desc}
                                    </p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}