import React from 'react'
import { useState } from 'react';
import axios from 'axios';

function Review() {
  const [name, setName] = useState('');
  const [review , setReview] = useState('');


  const [isCallSuccess , setIsCallSuccess] = useState(false);

  const ReviewApiUrl = 'https://back-api-231-be356ccc227f.herokuapp.com/api/review/';

  const handleReviewCall = async () => {
    try {
      const response = await axios.post(ReviewApiUrl, {
        name,
        review,
      });
      
      console.log('Review response:', response);
      setIsCallSuccess(true);

    } catch (error) {
      console.error('Error during Review call:', error);
      setIsCallSuccess(false);
    }
  }


  
  return (
   <>

      { isCallSuccess ? <h3 className='bg-pink-500 w-1/2 m-auto rounded-l-md rounded-r-lg p-2 shadow-lg shadow-gray-700 transition-all ease-in-out animate-bounce'>تم ارسال تقييمك بنجاح</h3> : null}

      <form className="max-w-sm mx-auto w-1/3 text-center mt-36 mb-52">
        <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">الاسم</label>
        <input type="text" onChange={(e)=> {setName(e.target.value)}} id="name" className="block w-full p-2 text-sm dark:text-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="اسمك" />
        <label for="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">تقييمك</label>
        <textarea id="message" onChange={(e)=>{setReview(e.target.value)}} rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="اكتب تقييمك ..."></textarea>
        <button type="submit" onClick={handleReviewCall} className="inline-flex items-center justify-center w-full px-4 py-2 mt-4 text-base font-medium text-white bg-pink-500 border border-transparent rounded-md shadow-sm hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
          تقييم
        </button>
      </form>

   </>
  )
}

export default Review