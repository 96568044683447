import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Slider from 'react-slick';






function Gallery() {

    const [imgs , setImages] = useState([])
    const getImages = async () => { 
        const url = 'http://localhost:8000/api/work_images/'
        const response = await axios.get(url)
        setImages(response.data)
        console.log(response.data)
    }

    useEffect(
        ()=>{
            getImages()
        },[]
    )

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
      };

    return (
          <Slider {...settings}>
            {imgs.map((image, key) => (
              <div key={key}>
                <img
                  src={image.image}
                  alt={`image ${key + 1}`}
                  className="w-1/3 object-cover object-center m-auto rounded-l-xl rounded-r-xl shadow-2xl h-2/3"
                />
              </div>
            ))}
          </Slider>
          
        )}
      
      


export default Gallery