import React from 'react'

function Shape(props) {
  return (
    <div>
        <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/5b2dcdf98934bedcbbc1ff63a6bf71a3f97fd23d263774c5e85c4ebe13b4e4e5?"
                    className={props}
                    />
    </div>
  )
}

export default Shape