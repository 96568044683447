import {  useState } from "react"
import cover2 from '../imgs/cover2.jpeg'
import clinicdo from '../imgs/clinicdo.png'
import vezeeta from '../imgs/vezeeta.png'
import AppointmentModal from "./AppointmentModal";
import { Link } from "react-router-dom";


export default () => {


        const [isModalOpen, setModalOpen] = useState(false);

        const openModal = () => {
        setModalOpen(true);
        };
    
        const closeModal = () => {
        setModalOpen(false);
        };

    return (
        <>
 
            <section className="py-16">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/5b2dcdf98934bedcbbc1ff63a6bf71a3f97fd23d263774c5e85c4ebe13b4e4e5?"
                    className="aspect-[2.08] object-contain object-center w-full fill-pink-500 overflow-hidden max-w-[287px] -rotate-90 absolute -left-20 transition-transform animate-pulse ease-in-out opacity-5 translate-y-11"
                    />
                <div className=" max-w-screen-xl mx-auto text-gray-600 gap-x-12 items-center justify-between overflow-hidden md:flex md:px-8">

                    <div className="  flex-none space-y-5 px-4 sm:max-w-lg md:px-0 lg:max-w-xl flex flex-col justify-center items-start text-left gap-y-4">
                            <h1 className="text-center text-base text-pink-500 font-semibold w-11/12 p-3 shadow-none shadow-zinc-600 rounded-l-lg rounded-r-lg m-auto animate-fadeIn">
                                دكتور ولاء صلاح
                            </h1>
                            <h2 className="animate-pulse text-4xl text-pink-500 font-extrabold md:text-5xl text-center animate-fadeIn Z-0">
                                ابتسامتك سر  جاذبيتك
                            </h2>
                            <p className="text-pink-500 w-11/12 text-center animate-fadeIn">
                                اختار المعاد المناسب ليك فى العياده <br/>
                                و احجز مودعك بتكة زرار
                            </p>

                        <div className=" gap-x-3 space-y-3 sm:flex sm:space-y-0 justify-center items-center w-11/12">
                            <a onClick={openModal} className="block py-2 px-4 text-center text-white font-medium bg-pink-500 duration-150 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg shadow-lg hover:shadow-none md:w-2/3 lg:w-3/4">
                                احجز الان
                            </a>
                            <Link to='/review'  className="flex items-center justify-center gap-x-2 py-2 px-4 text-gray-700 hover:text-gray-500 font-medium duration-150 active:bg-gray-100 border rounded-lg md:inline-flex">
                                قيمنا
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z" clipRule="evenodd" />
                                </svg>
                                
                            </Link>
                        </div>
                    </div>

                    {isModalOpen && <AppointmentModal isOpen={isModalOpen} onClose={closeModal} />}


                    <div className="flex-none mt-14 md:mt-0 md:max-w-xl">
                        <img
                            src={cover2}
                            className=" md:rounded-tl-[108px]"
                            alt=""
                        />
                    </div>
                </div>
                <div className="mt-28 px-4 md:px-8 animate-fadeIn">
                    <p className="text-center text-3xl text-gray-700 font-semibold">حائزة علي ثقة شركات</p>
                    <div className="flex justify-center items-center flex-wrap gap-x-12 gap-y-6 mt-6">
                        <img src={clinicdo} width="190" height="33" fill="none" className="animate-bounce"></img>
                        <img src={vezeeta} width="190" height="33" fill="none" className="animate-bounce"></img>
                    </div>
                </div>

            </section>
        </>
    )
}